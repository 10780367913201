//
// Vendors customization
//

////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY BELOW INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// Initialize
@import "init";

// Plugins
@import "./core/vendors/plugins/plugins";


//ngx-dropzone

ngx-dropzone {
    &.expandable > * {
        flex: 0 0 calc(33.33% - 8px)!important;
        width: calc(33.33% - 8px) !important;
        min-width: calc(33.33% - 8px) !important;
        margin: 4px !important;
        font-size: 10px;
    }
    ngx-dropzone-label {
        overflow: hidden;
        z-index: 5 !important;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
    ngx-dropzone-image-preview {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

