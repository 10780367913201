/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style.scss";
// Replace above style with this css file to enable RTL styles
// @import "./assets/css/style.rtl";
@import './assets/sass/plugins.scss';
// @import "./assets/css/style.rtl.css";
@import "./assets/sass/style.angular.scss";

// Keenicons - High quality and pixel perfect font icons available in 3 styles, duotone, outline and solid for Metronic elements
@import "./assets/plugins/keenicons/duotone/style.css";
@import "./assets/plugins/keenicons/outline/style.css";
@import "./assets/plugins/keenicons/solid/style.css";

/* Add application styles & imports to this file! */
@import url("https://cdn.datatables.net/1.10.16/css/jquery.dataTables.min.css");
.bg-1E1E2D{
    background-color: #1E1E2D  !important;
}
@import "~@ng-select/ng-select/themes/default.theme.css";